import {GetCpgCategories_CachedQuery} from '@data/__generated__/types.main'
import scrollTo from '@helpers/misc/scrollTo'
import useWidth from '@hooks/useWidth'
import MobileCategoriesMenu from '@page-components/Order/CategoryPage/Content/TopRow/MobileCategoriesMenu'
import cloneDeep from 'lodash/cloneDeep'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {Suspense, useEffect, useRef, useState} from 'react'
import {MdChevronRight as Chevron} from 'react-icons/md'
import useUserInfo from '../Menu/useUserInfo.main'
import styles from './styles.module.css'

type Category = GetCpgCategories_CachedQuery['website']['categories'][0]

type NavbarLinksProps = {
  links: any[]
  categories: Category[]
  cpg?: boolean
}

function InnerNavbarLinks(props: NavbarLinksProps) {
  const {links, categories, cpg} = props
  const router = useRouter()
  const [showMegaMenu, setShowMegaMenu] = useState(false)
  const [categoriesMegaMenu, setCategoriesMegaMenu] = useState([])
  const [positionMenu, setPositionMenu] = useState({x: 0, y: 0, triangleX: 0})
  const [open, setOpen] = useState(false)
  const pageWidth = useWidth()

  const {me} = useUserInfo()
  const isLoggedIn = me?._id
  const megamenu = useRef(null)

  const isMobile = pageWidth < 768

  let timer = null

  const handleMouseIn = (all, menu = false) => {
    if (timer) {
      clearTimeout(timer)
    }
    setShowMegaMenu(true)

    if (menu) {
      const targetposition = all.target.getBoundingClientRect()
      const {x, y, width} = targetposition
      const megamenuPosition = megamenu.current.getBoundingClientRect()
      const xPosition = (pageWidth - megamenuPosition.width) / 2
      setPositionMenu({
        x: xPosition,
        y: y,
        triangleX: x - xPosition + width / 3,
      })
    }
  }

  const handleMouseOut = () => {
    timer = setTimeout(() => {
      setShowMegaMenu(false)
    }, 500)
  }

  const handleClick = () => {
    setShowMegaMenu(false)
  }

  const sortCategories = () => {
    const categoriesInfo: Array<Category & {subcategories?: Category[]}> = []
    const sortedCategories = cloneDeep(categories)
    sortedCategories.sort((a, b) => {
      if (a.parent && b.parent) {
        return a.index - b.index
      }
      if (a.parent) {
        return 1
      }
      if (b.parent) {
        return -1
      }
      return a.index - b.index
    })

    for (const category of sortedCategories) {
      if (category.parent) {
        const parent = categoriesInfo.find(categoryMenu => categoryMenu._id === category.parent._id)
        if (parent) {
          parent.subcategories = parent.subcategories
            ? [...parent.subcategories, category]
            : [category]
        }
      } else {
        categoriesInfo.push(category)
      }
    }

    setCategoriesMegaMenu(categoriesInfo)
  }

  useEffect(() => {
    sortCategories()
    return () => {
      // cleanup function: clear timeout on unmount component
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [])

  if (!links || !links.length) return null

  const linksAvailable = isLoggedIn ? links : links.filter(link => !link.showLoggedIn)

  return (
    <div className={styles.container} style={{padding: cpg ? '5px 0' : '0 0 10px'}}>
      {linksAvailable.map((link, index) => {
        if (isMobile && link.showMegaMenu) {
          return (
            <a
              key={index}
              className={styles.link}
              rel="noopener noreferrer"
              // biome-ignore lint/a11y/useValidAnchor: <explanation>
              onClick={() => setOpen(true)}
            >
              {link.title}
            </a>
          )
        }
        if (!link.path.startsWith('/')) {
          return (
            <a
              href={link.path}
              target="_blank"
              key={index}
              className={styles.link}
              rel="noopener noreferrer"
            >
              {link.title}
            </a>
          )
        }

        const active = link.path === router.asPath

        return (
          <Suspense key={index} fallback={null}>
            <Link
              href={isMobile && showMegaMenu ? '/' : link.path}
              passHref
              onClick={() => {
                if (link.showMegaMenu && isMobile) {
                  setOpen(true)
                  return
                }
                if (!active) return
                const navbar = document.getElementsByClassName('mainNavbar')[0]
                const height = navbar.clientHeight
                scrollTo({element: navbar, top: height})
              }}
              suppressHydrationWarning
              className={active ? styles.active : styles.link}
              onMouseOver={event => {
                if (link.showMegaMenu && !isMobile) {
                  handleMouseIn(event, true)
                } else {
                  setShowMegaMenu(false)
                }
              }}
              onMouseOut={handleMouseOut}
            >
              {link.title}
            </Link>
          </Suspense>
        )
      })}
      {/* biome-ignore lint/a11y/useKeyWithMouseEvents: <explanation> */}
      <div
        ref={megamenu}
        className={styles.megamenu}
        onMouseOver={handleMouseIn}
        onMouseOut={handleMouseOut}
        style={{
          top: positionMenu.y + 34,
          left: positionMenu.x,
          visibility: showMegaMenu ? 'visible' : 'hidden',
        }}
      >
        {categoriesMegaMenu.map(category => {
          return (
            <div key={category._id} className={styles.category}>
              <Link
                href={`/pedir/category/${category._id}`}
                passHref
                prefetch={false}
                className={styles.categoryTitle}
                onClick={handleClick}
              >
                {category.name}
              </Link>
              {category.subcategories
                ? category.subcategories.map(subcategory => {
                    return (
                      <Link
                        key={subcategory._id}
                        href={`/pedir/category/${subcategory._id}`}
                        prefetch={false}
                        passHref
                        className={styles.subcategoryTitle}
                        onClick={handleClick}
                      >
                        {subcategory.name}
                      </Link>
                    )
                  })
                : null}
              {category.subcategories ? (
                <Link
                  href={`/pedir/category/${category._id}`}
                  passHref
                  prefetch={false}
                  className={styles.seeAll}
                  onClick={handleClick}
                >
                  Ver todos
                  <Chevron size={10} color="var(--primary-color)" />
                </Link>
              ) : null}
            </div>
          )
        })}
        <div className={styles.triangle} style={{top: -34, left: positionMenu.triangleX}} />
      </div>
      {open && <MobileCategoriesMenu setOpen={setOpen} />}
    </div>
  )
}

export function NavbarLinksSkeleton() {
  return <div className="h-[52px] w-full animate-pulse bg-gray-100 dark:bg-zinc-800" />
}

export default function NavbarLinks(props: NavbarLinksProps) {
  return (
    <Suspense fallback={<NavbarLinksSkeleton />}>
      <InnerNavbarLinks {...props} />
    </Suspense>
  )
}
